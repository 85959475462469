//##########################################//
//                                          //
//         CLASSI GESTIONE FLEXBOX          //
//                                          //
//##########################################//


// Flex
//--------------------------------------------------------------------//

.flex
{
    @include flexbox();
}

@include eq-mobile
{
    .flex-m
    {
        @include flexbox();
    }
}

@include eq-tablet
{
    .flex-t
    {
        @include flexbox();
    }
}

@include at-tablet
{
    .flex-td
    {
        @include flexbox();
    } 
}

@include at-desktop
{
    .flex-d
    {
        @include flexbox();
    }
}

//--------------------------------------------------------------------//


// Flex wrap
//--------------------------------------------------------------------//

.flex-wrap
{
    @include flex-wrap(wrap);
}

@include eq-mobile
{
    .flex-wrap-m
    {
        @include flex-wrap(wrap);
    }
}

@include eq-tablet
{
    .flex-wrap-t
    {
        @include flex-wrap(wrap);
    }
}

@include at-tablet
{
    .flex-wrap-td
    {
        @include flex-wrap(wrap);
    } 
}

@include at-desktop
{
    .flex-wrap-d
    {
        @include flex-wrap(wrap);
    }
}

//--------------------------------------------------------------------//


// Flex direction
//--------------------------------------------------------------------//

.flex-row
{
    @include flex-direction(row);
}
.flex-row-reverse
{
    @include flex-direction(row-reverse);
}
.flex-column
{
    @include flex-direction(column);
}
.flex-column-reverse
{
    @include flex-direction(column-reverse);
}

@include eq-mobile
{
    .flex-row-m
    {
        @include flex-direction(row);
    }
    .flex-row-reverse-m
    {
        @include flex-direction(row-reverse);
    }
    .flex-column-m
    {
        @include flex-direction(column);
    }
    .flex-column-reverse-m
    {
        @include flex-direction(column-reverse);
    }
}

@include eq-tablet
{
    .flex-row-t
    {
        @include flex-direction(row);
    }
    .flex-row-reverse-t
    {
        @include flex-direction(row-reverse);
    }
    .flex-column-t
    {
        @include flex-direction(column);
    }
    .flex-column-reverse-t
    {
        @include flex-direction(column-reverse);
    }
}

@include at-tablet
{
    .flex-row-td
    {
        @include flex-direction(row);
    }
    .flex-row-reverse-td
    {
        @include flex-direction(row-reverse);
    }
    .flex-column-td
    {
        @include flex-direction(column);
    }
    .flex-column-reverse-td
    {
        @include flex-direction(column-reverse);
    }
}

@include at-desktop
{
    .flex-row-d
    {
        @include flex-direction(row);
    }
    .flex-row-reverse-d
    {
        @include flex-direction(row-reverse);
    }
    .flex-column-d
    {
        @include flex-direction(column);
    }
    .flex-column-reverse-d
    {
        @include flex-direction(column-reverse);
    }
}

//--------------------------------------------------------------------//


// Justify content
//--------------------------------------------------------------------//

.justify-content-start
{
    @include justify-content(flex-start);
}

.justify-content-end
{
    @include justify-content(flex-end);
}

.justify-content-center
{
    @include justify-content(center);
}

.justify-content-between
{
    @include justify-content(space-between);
}

.justify-content-around
{
    @include justify-content(space-around);
}

@include eq-mobile
{
    .justify-content-start-m
    {
        @include justify-content(flex-start);
    }

    .justify-content-end-m
    {
        @include justify-content(flex-end);
    }

    .justify-content-center-m
    {
        @include justify-content(center);
    }

    .justify-content-between-m
    {
        @include justify-content(space-between);
    }

    .justify-content-around-m
    {
        @include justify-content(space-around);
    }
}

@include eq-tablet
{
    .justify-content-start-t
    {
        @include justify-content(flex-start);
    }

    .justify-content-end-t
    {
        @include justify-content(flex-end);
    }

    .justify-content-center-t
    {
        @include justify-content(center);
    }

    .justify-content-between-t
    {
        @include justify-content(space-between);
    }

    .justify-content-around-t
    {
        @include justify-content(space-around);
    }
}

@include at-tablet
{
    .justify-content-start-td
    {
        @include justify-content(flex-start);
    }

    .justify-content-end-td
    {
        @include justify-content(flex-end);
    }

    .justify-content-center-td
    {
        @include justify-content(center);
    }

    .justify-content-between-td
    {
        @include justify-content(space-between);
    }

    .justify-content-around-td
    {
        @include justify-content(space-around);
    }
}

@include at-desktop
{
    .justify-content-start-d
    {
        @include justify-content(flex-start);
    }

    .justify-content-end-d
    {
        @include justify-content(flex-end);
    }

    .justify-content-center-d
    {
        @include justify-content(center);
    }

    .justify-content-between-d
    {
        @include justify-content(space-between);
    }

    .justify-content-around-d
    {
        @include justify-content(space-around);
    }
}

//--------------------------------------------------------------------//


// Align items
//--------------------------------------------------------------------//

.align-items-start
{
    @include align-items(flex-start);
}
.align-items-end
{
    @include align-items(flex-end);
}
.align-items-center
{
    @include align-items(center);
}

@include eq-mobile
{
    .align-items-start-m
    {
        @include align-items(flex-start);
    }
    .align-items-end-m
    {
        @include align-items(flex-end);
    }
    .align-items-center-m
    {
        @include align-items(center);
    }
}
@include eq-tablet
{
    .align-items-start-t
    {
        @include align-items(flex-start);
    }
    .align-items-end-t
    {
        @include align-items(flex-end);
    }
    .align-items-center-t
    {
        @include align-items(center);
    }
}
@include at-tablet
{
    .align-items-start-td
    {
        @include align-items(flex-start);
    }
    .align-items-end-td
    {
        @include align-items(flex-end);
    }
    .align-items-center-td
    {
        @include align-items(center);
    } 
}
@include at-desktop
{
    .align-items-start-d
    {
        @include align-items(flex-start);
    }
    .align-items-end-d
    {
        @include align-items(flex-end);
    }
    .align-items-center-d
    {
        @include align-items(center);
    }
}

//--------------------------------------------------------------------//


// Align self
//--------------------------------------------------------------------//

.align-self-start
{
    @include align-self(flex-start);
}
.align-self-end
{
    @include align-self(flex-end);
}
.align-self-center
{
    @include align-self(center);
}

@include eq-mobile
{
    .align-self-start-m
    {
        @include align-self(flex-start);
    }
    .align-self-end-m
    {
        @include align-self(flex-end);
    }
    .align-self-center-m
    {
        @include align-self(center);
    }
}
@include eq-tablet
{
    .align-self-start-t
    {
        @include align-self(flex-start);
    }
    .align-self-end-t
    {
        @include align-self(flex-end);
    }
    .align-self-center-t
    {
        @include align-self(center);
    }
}
@include at-tablet
{
    .align-self-start-td
    {
        @include align-self(flex-start);
    }
    .align-self-end-td
    {
        @include align-self(flex-end);
    }
    .align-self-center-td
    {
        @include align-self(center);
    } 
}
@include at-desktop
{
    .align-self-start-d
    {
        @include align-self(flex-start);
    }
    .align-self-end-d
    {
        @include align-self(flex-end);
    }
    .align-self-center-d
    {
        @include align-self(center);
    }
}

//--------------------------------------------------------------------//


// Align content
//--------------------------------------------------------------------//

.align-content-start
{
    @include align-content(flex-start);
}
.align-content-end
{
    @include align-content(flex-end);
}
.align-content-center
{
    @include align-content(center);
}

@include eq-mobile
{
    .align-content-start-m
    {
        @include align-content(flex-start);
    }
    .align-content-end-m
    {
        @include align-content(flex-end);
    }
    .align-content-center-m
    {
        @include align-content(center);
    }
}
@include eq-tablet
{
    .align-content-start-t
    {
        @include align-content(flex-start);
    }
    .align-content-end-t
    {
        @include align-content(flex-end);
    }
    .align-content-center-t
    {
        @include align-content(center);
    }
}
@include at-tablet
{
    .align-content-start-td
    {
        @include align-content(flex-start);
    }
    .align-content-end-td
    {
        @include align-content(flex-end);
    }
    .align-content-center-td
    {
        @include align-content(center);
    }
}
@include at-desktop
{
    .align-content-start-d
    {
        @include align-content(flex-start);
    }
    .align-content-end-d
    {
        @include align-content(flex-end);
    }
    .align-content-center-d
    {
        @include align-content(center);
    }
}

//--------------------------------------------------------------------//


// Order
//--------------------------------------------------------------------//

.order-1
{
    order: 1;
}

.order-2
{
    order: 2;
}

.order-3
{
    order: 3;
}

@include eq-mobile
{
    .order-m-1
    {
        order: 1;
    }

    .order-m-2
    {
        order: 2;
    }

    .order-m-3
    {
        order: 3;
    }
}

@include eq-tablet
{
    .order-t-1
    {
        order: 1;
    }

    .order-t-2
    {
        order: 2;
    }

    .order-t-3
    {
        order: 3;
    }
}

@include at-tablet
{
    .order-td-1
    {
        order: 1;
    }

    .order-td-2
    {
        order: 2;
    }

    .order-td-3
    {
        order: 3;
    }
}

@include at-desktop
{
    .order-d-1
    {
        order: 1;
    }

    .order-d-2
    {
        order: 2;
    }

    .order-d-3
    {
        order: 3;
    }
}

//--------------------------------------------------------------------//