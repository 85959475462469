//##########################################//
//                                          //
//           CONTAINERS DEL SITO            //
//                                          //
//##########################################//

.container-page
{
    width: 100%;
    margin: 0px auto;
    max-width: 1440px;
}

.container
{
    width: 100%;
    margin: 0px auto;
    max-width: 1440px;

    @include eq-mobile
    {
        padding-left: 14px;
        padding-right: 14px;
    }

    @include at-tablet
    {
        padding-left: 80px;
        padding-right: 80px;
    }

}

.container-small
{
    width: 100%;
    margin: 0px auto;
    max-width: 1360px;

    @include eq-mobile
    {
        padding-left: 14px;
        padding-right: 14px;
    }

    @include at-tablet
    {
        padding-left: 100px;
        padding-right: 100px;
    }
}
.container-mini
{
    width: 100%;
    margin: 0px auto;
    max-width: 700px;

    @include eq-mobile
    {
        padding-left: 14px;
        padding-right: 14px;
    }

    @include at-tablet
    {
        padding-left: 100px;
        padding-right: 100px;
    }
}
.container-foreground
{
    width: 100%;
    margin: 0px auto;
    max-width: 1440px;

    &__inner
    {
        border-radius: 5px;
        background-color: $white;
        box-shadow: 0px 10px 40px rgba(0, 104, 150, 0.2);
    }

    @include eq-mobile
    {
        padding-left: 14px;
        padding-right: 14px;

        &__inner
        {
            padding: 15px 14px 20px;
        }
    }

    @include at-tablet
    {
        padding-left: 40px;
        padding-right: 40px;

        &__inner
        {
            padding: 30px 40px 50px;
        }
    }
}