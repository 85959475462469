//##########################################//
//                                          //
//            UTILITIES DEL SITO            //
//                                          //
//##########################################//

// Heights
//--------------------------------------------------------------------//

.h-10
{
    min-height: 10vh;
}
.h-20
{
    min-height: 20vh;
}
.h-30
{
    min-height: 30vh;
}
.h-40
{
    min-height: 40vh;
}
.h-50
{
    min-height: 50vh;
}
.h-60
{
    min-height: 60vh;
}
.h-70
{
    min-height: 70vh;
}
.h-80
{
    min-height: 80vh;
}
.h-90
{
    min-height: 90vh;
}
.h-100
{
    min-height: 100vh;
}

@include eq-mobile
{
    .h-m-10
    {
        min-height: 10vh;
    }
    .h-m-20
    {
        min-height: 20vh;
    }
    .h-m-30
    {
        min-height: 30vh;
    }
    .h-m-40
    {
        min-height: 40vh;
    }
    .h-m-50
    {
        min-height: 50vh;
    }
    .h-m-60
    {
        min-height: 60vh;
    }
    .h-m-70
    {
        min-height: 70vh;
    }
    .h-m-80
    {
        min-height: 80vh;
    }
    .h-m-90
    {
        min-height: 90vh;
    }
    .h-m-100
    {
        min-height: 100vh;
    }
}

@include eq-tablet
{
    .h-t-10
    {
        min-height: 10vh;
    }
    .h-t-20
    {
        min-height: 20vh;
    }
    .h-t-30
    {
        min-height: 30vh;
    }
    .h-t-40
    {
        min-height: 40vh;
    }
    .h-t-50
    {
        min-height: 50vh;
    }
    .h-t-60
    {
        min-height: 60vh;
    }
    .h-t-70
    {
        min-height: 70vh;
    }
    .h-t-80
    {
        min-height: 80vh;
    }
    .h-t-90
    {
        min-height: 90vh;
    }
    .h-t-100
    {
        min-height: 100vh;
    }
}

@include at-desktop
{
    .h-d-10
    {
        min-height: 10vh;
    }
    .h-d-20
    {
        min-height: 20vh;
    }
    .h-d-30
    {
        min-height: 30vh;
    }
    .h-d-40
    {
        min-height: 40vh;
    }
    .h-d-50
    {
        min-height: 50vh;
    }
    .h-d-60
    {
        min-height: 60vh;
    }
    .h-d-70
    {
        min-height: 70vh;
    }
    .h-d-80
    {
        min-height: 80vh;
    }
    .h-d-90
    {
        min-height: 90vh;
    }
    .h-d-100
    {
        min-height: 100vh;
    }
}

//--------------------------------------------------------------------//


// Shadows
//--------------------------------------------------------------------//

.shadow-small
{
    @include box-shadow(0px, .125rem, .25rem, 0px, rgba(0,0,0,.075));
}

.shadow-regular
{
    @include box-shadow(0px, .5rem, 1rem, 0px, rgba(0,0,0,.15));
}

.shadow-large
{
    @include box-shadow(0px, 1rem, 3rem, 0px, rgba(0,0,0,.175));
}

//--------------------------------------------------------------------//