//##########################################//
//                                          //
//      VARIABILI DI STILE DEL SITO         //
//                                          //
//##########################################//


// Padding
//------------------------------------------------------------------------------//
$padding-base: 40px;
$padding-mobile:    $padding-base * 0.5;
$padding-tablet:    80px;
$padding-desktop:   80px;



// Margin
//------------------------------------------------------------------------------//
$margin-small:  20px;
$margin-medium: 40px;
$margin-large:  100px;