//##########################################//
//                                          //
//      COLORI TESTO                        //
//                                          //
//##########################################//


// text-primary
//--------------------------------------------------------------------///
.text-primary
{
    color: $primary;
}

// text-secondary
//--------------------------------------------------------------------///
.text-secondary
{
    color: $secondary;
}

// text-light
//--------------------------------------------------------------------///
.text-light
{
    color: $light;
}

// text-medium
//--------------------------------------------------------------------///
.text-medium
{
    color: $medium;
}

// text-dark
//--------------------------------------------------------------------///
.text-dark
{
    color: $dark;
}

// text-grey
//--------------------------------------------------------------------///
.text-grey
{
    color: $grey;
}

// text-red
//--------------------------------------------------------------------///
.text-red
{
    color: $red;
}

// text-green
//--------------------------------------------------------------------///
.text-green
{
    color: $green;
}

// text-black
//--------------------------------------------------------------------///
.text-black
{
    color: $black;
}

// text-white
//--------------------------------------------------------------------///
.text-white
{
    color: $white;
}

