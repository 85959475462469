//##########################################//
//                                          //
//      COLORI BACKGROUND                   //
//                                          //
//##########################################//


// bg-primary
//--------------------------------------------------------------------///
.bg-primary
{
    background-color: $primary;
}

// bg-secondary
//--------------------------------------------------------------------///
.bg-secondary
{
    background-color: $secondary;
}

// bg-light
//--------------------------------------------------------------------///
.bg-light
{
    background-color: $light;
}

// bg-medium
//--------------------------------------------------------------------///
.bg-medium
{
    background-color: $medium;
}

// bg-dark
//--------------------------------------------------------------------///
.bg-dark
{
    background-color: $dark;
}

// bg-grey
//--------------------------------------------------------------------///
.bg-grey
{
    background-color: $grey;
}

// bg-red
//--------------------------------------------------------------------///
.bg-red
{
    background-color: $red;
}

// bg-green
//--------------------------------------------------------------------///
.bg-green
{
    background-color: $green;
}

// bg-black
//--------------------------------------------------------------------///
.bg-black
{
    background-color: $black;
}

// bg-white
//--------------------------------------------------------------------///
.bg-white
{
    background-color: $white;
}

