//##########################################//
//                                          //
//      ALLINEAMENTO TESTI                  //
//                                          //
//##########################################//


// Text left
//--------------------------------------------------------------------///
.text-left
{
    text-align: left;
}

@include eq-mobile
{
    .text-m-left
    {
        text-align: left;
    }
}

@include eq-tablet
{
    .text-t-left
    {
        text-align: left;
    }
}

@include at-tablet
{
    .text-td-left
    {
        text-align: left;
    }
}

@include at-desktop
{
    .text-d-left
    {
        text-align: left;
    }
}

// Text center
//--------------------------------------------------------------------///
.text-center
{
    text-align: center;
}

@include eq-mobile
{
    .text-m-center
    {
        text-align: center;
    }
}

@include eq-tablet
{
    .text-t-center
    {
        text-align: center;
    }
}

@include at-tablet
{
    .text-td-center
    {
        text-align: center;
    }
}

@include at-desktop
{
    .text-d-center
    {
        text-align: center;
    }
}

// Text right
//--------------------------------------------------------------------///
.text-right
{
    text-align: right;
}

@include eq-mobile
{
    .text-m-right
    {
        text-align: right;
    }
}

@include eq-tablet
{
    .text-t-right
    {
        text-align: right;
    }
}

@include at-tablet
{
    .text-td-right
    {
        text-align: right;
    }
}

@include at-desktop
{
    .text-d-right
    {
        text-align: right;
    }
}