
                  @import "~scss/helpers/mixins";
                  @import "~scss/helpers/resolutions";
                  @import "~scss/common/variables";
                  @import "~scss/common/animations";
                  @import "~scss/common/colors";
                  @import "~scss/common/typography";
                
// Component Header
header
{
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 100;
    opacity: 1;
    transition: background-color $timeMedium $easeCubicInOut, 
                opacity $timeFast $easeQuadInOut $timeMedium;

    .header-container
    {
        position: relative;
        @include flexbox();
        @include flex-wrap(wrap);
        @include align-items(center);
        @include justify-content(space-between);
        transition: padding $timeMedium $easeCubicInOut;
    }

    &.open-menu
    {
        background-color: $primary !important;
    }

    .header-layout
    {
        .logo
        {
            position: relative;
            overflow: hidden;
            z-index: 21;

            a
            {
                display: inline-block;
            }

            &__neg,
            &__pos
            {
                transition: opacity $timeMedium $easeCubicInOut;
            }

            &__neg
            {
                position: relative;
                opacity: 1;
                z-index: 1;
            }

            &__pos
            {
                position: absolute;
                top: 0px;
                left: 0px;
                opacity: 0;
                @include size(100%);
            }
        }

        .menu-container
        {
            position: absolute;
            top: 0px;
            right: 0px;
            width: 100%;
            height: 100vh;
            min-height: -webkit-fill-available;
            background-color: transparent;
            opacity: 0;
            visibility: hidden;
            overflow: auto;
            transform: translateY(-100%);
            z-index: 20;
            transition: transform 0.4s ease-in-out $timeFast, 
                        opacity 0s linear 0.6s, 
                        visibility 0s linear 0.6s, 
                        background-color 0s linear 0.6s;

            .menu-wrapper
            {
                position: relative;
                height: 100%;
                overflow-y: auto;
                overflow-x: hidden;
                opacity: 0;
                transition: opacity $timeFast linear;
            }

            ul
            {
                > li
                {
                    a
                    {
                        position: relative;
                        display: inline-block;
                        
                        &::after
                        {
                            content: '';
                            position: absolute;
                            bottom: 0px;
                            left: 0px;
                            @include size(0%, 2px);
                            transition: width $timeFast $easeCubicInOut;
                        }
                    }

                    &.active a,
                    a:hover
                    {
                        &::after
                        {
                            width: 100%;
                        }
                    }
                }
            }
        }

        .menu-right
        {
            position: relative;
            @include flexbox();
            @include flex-wrap(wrap);
            @include align-items(center);
            z-index: 21;
        }

        .menu-primary-desk
        {
            opacity: 1;
            visibility: visible;
            transition: visibility 0s $easeCubicInOut,
                        opacity $timeMedium $easeCubicInOut;

            ul
            {
                li
                {
                    display: inline-block;

                    &:not(:first-child)
                    {
                        margin-left: 25px;
                    }

                    a
                    {
                        position: relative;
                        display: inline-block;
                        font-size: em(16px);
                        line-height: em(19px);
                        font-weight: $font-weight-bold;
                        text-decoration: none;
                        color: $white;
                        transition: color $timeMedium $easeCubicInOut;

                        &::after
                        {
                            content: '';
                            position: absolute;
                            bottom: 0px;
                            left: 0px;
                            @include size(0%, 2px);
                            background-color: $white;
                            transition: width $timeFast $easeCubicInOut;
                        }
                    }

                    &.active a,
                    a:hover
                    {
                        &::after
                        {
                            width: 100%;
                        }
                    }
                }
            }
        }

        .language-switch
        {
            position: relative;


            .im-accordion
            {
                &__toggle
                {
                    padding: 0px;

                    &.open
                    {
                        .im-accordion__icon
                        {
                            transform: rotate(180deg);
                        }
                    }
                }

                &__title
                {
                    font-size: em(16px);
                    line-height: em(19px);
                    font-weight: $font-weight-bold;
                    text-decoration: none;
                    color: $white;
                    text-transform: capitalize;
                    transition: color $timeMedium $easeCubicInOut;
                }

                &__icon
                {
                    svg
                    {
                        fill: $white;
                        @include size(24px);
                        transition: fill $timeMedium $easeCubicInOut;
                    }
                }

                &__content
                {
                    @include centerX();
                    top: calc(100% + 10px);
                    width: min-content;
                    z-index: 100;

                    border-radius: 5px;
                    background-color: $white;
                    box-shadow: 0px 10px 40px rgba(0, 104, 150, 0.2);

                    [data-wrapper-height]
                    {
                        padding: 5px;
                    }

                    ul
                    {
                        li
                        {
                            &:not(:first-child)
                            {
                                margin-top: 3px;
                            }

                            a
                            {
                                @include flexbox();
                                @include align-items(center);
                                padding: 12px 36px 12px 12px;
                                background-color: $white;
                                border-radius: 5px;
                                text-transform: capitalize;
                                text-decoration: none;
                                transition: background-color $timeFast $easeCubicInOut;

                                &:hover
                                {
                                    background-color: $secondary_light;
                                }

                                span
                                {
                                    font-size: em(12px);
                                    line-height: em(16px, 12px);
                                    font-weight: $font-weight-bold;
                                    text-decoration: none;
                                }

                                svg
                                {
                                    display: inline-block;
                                    @include size(20px);
                                    margin-right: 10px;
                                }
                            }

                            &.active
                            {
                                a
                                {
                                    background-repeat: no-repeat;
                                    background-size: 17px;
                                    background-position: calc(100% - 13px) center;
                                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%2300A0DF' fill-rule='evenodd' d='M20.707 6.293a1 1 0 0 1 0 1.414l-10 10a1 1 0 0 1-1.414 0l-5-5a1 1 0 1 1 1.414-1.414L10 15.586l9.293-9.293a1 1 0 0 1 1.414 0Z' clip-rule='evenodd'/%3E%3C/svg%3E");
                                    background-color: $secondary_light;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.open-menu 
        {
            .menu-primary-desk
            {
                opacity: 0;
                visibility: hidden;
                transition: visibility 0s $easeCubicInOut $timeMedium,
                        opacity $timeMedium $easeCubicInOut;
            }

            .menu-container
            {
                opacity: 1;
                visibility: visible;
                transform: translateY(0%);
                transition: transform 0.4s ease-in-out, opacity 0s linear, visibility 0s linear;

                .menu-wrapper
                {
                    opacity: 1;
                    transition: opacity $timeFast linear 0.4s;
                }
            }

            .menu-primary-desk
            {
                ul
                {
                    li
                    {
                        a
                        {
                            color: $primary;
                        }
                    }
                }
            }
        }

        @include nav-compressed
        {
            .header-container
            {
                padding: 35px 20px 20px;
            }

            .header-top
            {
                > .container
                {
                    @include flexbox();
                    @include flex-wrap(wrap);
                    @include align-items(center);
                    @include justify-content(space-between);
                }
            }

            .logo
            {
                @include size(60%, 100%);
                max-width: 220px;
                max-height: 27px;

                a
                {
                    @include size(100%);
                }

                img
                {
                    @include size(100%);
                }
            }

            .menu-right
            {
                position: relative;
                z-index: 21;

                .menu-primary-desk
                {
                    display: none;
                }

                .language-switch
                {
                    margin-right: 20px;
                }
            }

            .menu-container
            {
                padding: 170px 20px 0px;

                .menu-wrapper
                {
                    padding-bottom: 40px;
                    
                    .menu
                    {
                        &--primary
                        {
                            ul
                            {
                                li
                                {
                                    &:not(:first-child)
                                    {
                                        margin-top: 40px;
                                    }

                                    a
                                    {
                                        color: $primary;
                                        font-size: em(20px);
                                        line-height: em(23px, 20px);
                                        font-weight: $font-weight-bold;
                                        text-decoration: none;
                                    }
                                }
                            }
                        }
                        
                        &--secondary
                        {
                            margin-top: 50px;

                            ul
                            {
                                li
                                {
                                    &:not(:first-child)
                                    {
                                        margin-top: 40px;
                                    }

                                    a
                                    {
                                        color: $primary;
                                        font-size: em(16px);
                                        line-height: em(19px, 16px);
                                        font-weight: $font-weight-bold;
                                        text-decoration: none;
                                    }
                                }
                            }
                        }
                    }

                    ul
                    {
                        > li
                        {
                            a
                            {   
                                &::after
                                {
                                    background-color: $primary;
                                }
                            }
                        }
                    }
                }
            }

            &.open-menu
            {
                .logo
                {
                    &__neg
                    {
                        opacity: 0 !important;
                    }

                    &__pos
                    {
                        opacity: 1 !important;
                    }
                }

                .menu-container
                {
                    background-color: $white;
                }

                .header__burger
                {
                    &-line
                    {
                        background-color: $primary !important;
                    }
                }
            }
        }

        @include nav-expanded
        {
            .header-container
            {
                padding: 30px 40px;
                

                > .container
                {
                    position: relative;
                }
            }

            .menu-right
            {
                max-width: calc(100% - 300px);

                .menu-primary-desk
                {
                    position: relative;
                    padding-right: 30px;
                    margin-right: 30px;

                    &::after
                    {
                        content: '';
                        @include centerY();
                        right: 0px;
                        @include size(1px, 20px);
                        background-color: $white;
                        transition: background-color $timeMedium $easeCubicInOut;
                    }
                }

                .language-switch
                {
                    margin-right: 40px;
                }
            }

            .logo
            {
                @include size(100%);
                max-width: 240px;
                max-height: 30px;

                a
                {
                    @include size(100%);
                }

                &__pos,
                &__neg
                {
                    @include size(100%);
                }
            }

            .menu-container
            {
                &::before
                {
                    content: '';
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    @include size(100%);
                    background-position: center;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-image: url('../../img/share/bg-menu.png');
                }

                .menu-wrapper
                {
                    padding: 100px 50px;
                    @include flexbox();
                    @include flex-wrap(wrap);
                    @include justify-content(center);
                    @include align-items(center);

                    .menu
                    {
                        &--primary
                        {
                            width: 400px;
                            min-height: 400px;
                            margin-right: 60px;

                            ul
                            {
                                li
                                {
                                    &:not(:first-child)
                                    {
                                        margin-top: 20px;
                                    }

                                    a
                                    {
                                        color: $white;
                                        font-size: em(40px);
                                        line-height: em(48px, 40px);
                                        font-weight: $font-weight-bold;
                                        text-decoration: none;
                                    }
                                }
                            }
                        }
                        
                        &--secondary
                        {
                            min-height: 400px;

                            ul
                            {
                                li
                                {
                                    &:not(:first-child)
                                    {
                                        margin-top: 20px;
                                    }

                                    a
                                    {
                                        color: $white;
                                        font-size: em(18px);
                                        line-height: em(25px, 18px);
                                        font-weight: $font-weight-bold;
                                        text-decoration: none;
                                    }
                                }
                            }
                        }
                    }

                    ul
                    {
                        > li
                        {
                            a
                            {   
                                &::after
                                {
                                    background-color: $white;
                                }
                            }
                        }
                    }
                }
            }

            &.open-menu
            {
                .logo
                {
                    &__neg
                    {
                        opacity: 1 !important;
                    }

                    &__pos
                    {
                        opacity: 0 !important;
                    }
                }

                .menu-container
                {
                    background-color: $primary;
                }

                .language-switch
                {
                    .im-accordion
                    {
                        &__title
                        {
                            color: $white !important;
                        }

                        &__icon
                        {
                            svg
                            {
                                fill: $white !important;
                            }
                        }
                    }
                }

                .header__burger
                {
                    &-line
                    {
                        background-color: $white !important;
                    }
                }
            }
        }
    }

    // STILE header__burger ICON
    .header__burger
    {
        position: relative;
        @include flexbox();
        @include flex-wrap(wrap);
        @include align-items(center);
        text-decoration: none;
        transform: rotate(0);
        font-size: unset;
        line-height: unset;
        transition: transform $timeMedium $easeCubicInOut;
        text-align: right;

        &-lines
        {
            @include flexbox();
            @include flex-direction(column);
            @include justify-content(space-between);
            @include size(18px, 16px);
            margin-top: 2px;
        }
        
        &-line
        {
            display: block;
            opacity: 1;
            margin-right: auto;
            margin-left: 0px;
            background-color: $white;
            transform: translateY(0px) rotate(0deg);
            transition: transform $timeMedium $easeCubicInOut,
                        width $timeMedium linear,
                        opacity $timeMedium linear,
                        background-color $timeMedium linear;

            &:nth-child(1)
            {
                @include size(10px, 2px);
            }    
        
            &:nth-child(2)
            {
                margin-top: 3px;
                @include size(18px, 2px);
            }
            
            &:nth-child(3)
            {
                margin-top: 3px;
                @include size(10px, 2px);
            }
        }

        &.open
        {
            .header__burger-line
            {
                width: 17px;

                &:nth-child(1)
                {
                    transform: translateY(7px) rotate(45deg);
                }
                &:nth-child(2)
                {
                    opacity: 0;
                }
                &:nth-child(3)
                {
                    transform: translateY(-7px) rotate(-45deg);
                }
            }
        }

        @include nav-compressed
        {
            &-text
            {
                margin-right: 10px;
            }
        }

        @include nav-expanded
        {

        }
    }
}

// HTML BODY OVERFLOW
html
{
    &.ovfw-hid-nav-mob body
    {
        overflow: hidden;
    }
}

// SCROLL DETECT
body
{
    &.scroll-detect
    {
        header
        {
            // position: fixed;
            top: 0px;
            left: 0px;
            opacity: 0;
            transform: translate(0%, -100%);

            .header-layout
            {
                .logo
                {
                    &__neg
                    {
                        opacity: 0;
                    }

                    &__pos
                    {
                        opacity: 1;
                    }
                }

                .menu-primary-desk
                {
                    &::after
                    {
                        background-color: $primary;
                    }

                    ul
                    {
                        li
                        {
                            a
                            {
                                color: $primary;

                                &::after
                                {
                                    background-color: $primary;
                                }
                            }
                        }
                    }
                }

                .language-switch
                {
                    .im-accordion
                    {
                        &__title
                        {
                            color: $primary;
                        }

                        &__icon
                        {
                            svg
                            {
                                fill: $primary;
                            }
                        }
                    }
                }
            }

            .header__burger
            {
                &-line
                {
                    background-color: $primary;
                }
            }
        }
    }

    &.scroll-down,
    &.scroll-up
    {
        header
        {
            top: 0px;
            opacity: 1;
            transition: transform 0.6s ease-in-out, visibility 0s linear 0.6s;
        }
    }

    &.scroll-down
    {
        header
        {
            visibility: hidden;
            transform: translate(0%, -100%);
        }
    }

    &.scroll-up
    {
        header
        {
            position: fixed;
            visibility: visible;
            background-color: $white;
            transition: transform 0.6s ease-in-out, visibility 0s linear;
        }
    }

    // STILE colori di partenza Header
    &:not(.scroll-detect)
    {
        header.positive
        {
            .header-layout
            {
                .logo
                {
                    &__neg
                    {
                        opacity: 0;
                    }

                    &__pos
                    {
                        opacity: 1;
                    }
                }

                .menu-primary-desk
                {
                    &::after
                    {
                        background-color: $primary;
                    }

                    ul
                    {
                        li
                        {
                            a
                            {
                                color: $primary;

                                &::after
                                {
                                    background-color: $primary;
                                }
                            }
                        }
                    }
                }

                .language-switch
                {
                    .im-accordion
                    {
                        &__title
                        {
                            color: $primary;
                        }

                        &__icon
                        {
                            svg
                            {
                                fill: $primary;
                            }
                        }
                    }
                }
            }

            .header__burger
            {
                &-line
                {
                    background-color: $primary;
                }
            }

            &.fill
            {
                background-color: $white;
            }
        }
    }

    @include nav-compressed
    {
        &.scroll-detect
        {
            header
            {
                .header-layout
                {
                    .header-container
                    {
                        padding-top: 15px;
                        padding-bottom: 15px;

                        .menu-container
                        {
                            padding-top: 100px;
                        }
                    }
                }
            }
        }

        &.scroll-up
        {
            header
            {
                transform: translate(0%, calc(-100% + 57px));
            }
        }
    }

    @include nav-expanded
    {
        &.scroll-detect
        {
            header
            {
                .header-layout
                {
                    .header-container
                    {
                        padding-top: 15px;
                        padding-bottom: 15px;
                    }
                }
            }
        }

        &.scroll-up
        {
            header
            {
                transform: translate(0%, calc(-100% + 60px));
            }
        }
    }
}