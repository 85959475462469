
                  @import "~scss/helpers/mixins";
                  @import "~scss/helpers/resolutions";
                  @import "~scss/common/variables";
                  @import "~scss/common/animations";
                  @import "~scss/common/colors";
                  @import "~scss/common/typography";
                
// COMPONENTE Accordion
//--------------------------------------------------------------------//

.im-accordion
{
    &__toggle
    {
        cursor: pointer;
        padding-top: 10px;
        padding-bottom: 10px;
        
    
        &.open
        {
            .im-accordion__icon
            {
                transform: rotate(45deg);   
            }
        }
    }

    &__icon
    {
        display: inline-block;
        transform: rotate(0deg);
        transition: transform $timeMedium $easeCubicInOut;   

        span
        {
            display: block;
            position: relative;
            @include size(24px);

            &::before,
            &::after
            {
                content: '';
                @include center();
                background-color: $primary;
            }

            &::before
            {
                @include size(100%, 1px);
            }

            &::after
            {
                @include size(1px, 100%);
            }
        }

        svg
        {
            display: block;
            @include size(24px);
        }
    }
    
    &__content
    {
        overflow: hidden;
        height: 0px;
        transition: height $timeMedium $easeCubicInOut;
    
        > [data-wrapper-height]
        {
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }

    &.react &
    {
        &__content
        {
            height: auto;
            max-height: 0px;
            transition: max-height $timeMedium cubic-bezier(0, 1, 0, 1);

            &.open
            {
                max-height: 9999px;
                transition: max-height $timeMedium cubic-bezier(1, 0, 1, 0);
            }
        }
    }
}

//--------------------------------------------------------------------//
