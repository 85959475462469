//##########################################//
//                                          //
//      COLONNE GRIGLIA                     //
//                                          //
//##########################################//

// Colonne generiche, per tutti i breakpoints
//--------------------------------------------------------------------///
.col-1
{
    width: 8.3333333333%;
}

.col-2
{
    width: 16.6666666667%;
}

.col-3
{
    width: 25%;
}

.col-4
{
    width: 33.3333333333%;
}

.col-5
{
    width: 41.6666666667%;
}

.col-6
{
    width: 50%;
}

.col-7
{
    width: 58.3333333333%;
}

.col-8
{
    width: 66.6666666667%;
}

.col-9
{
    width: 75%;
}

.col-10
{
    width: 83.3333333333%;
}

.col-11 
{
    width: 91.6666666667%;
}
  
.col-12 
{
    width: 100%;
}

// Colonne mobile
//--------------------------------------------------------------------///
@include eq-mobile
{
    .col-m-1
    {
        width: 8.3333333333%;
    }
    
    .col-m-2
    {
        width: 16.6666666667%;
    }
    
    .col-m-3
    {
        width: 25%;
    }
    
    .col-m-4
    {
        width: 33.3333333333%;
    }
    
    .col-m-5
    {
        width: 41.6666666667%;
    }
    
    .col-m-6
    {
        width: 50%;
    }
    
    .col-m-7
    {
        width: 58.3333333333%;
    }
    
    .col-m-8
    {
        width: 66.6666666667%;
    }
    
    .col-m-9
    {
        width: 75%;
    }
    
    .col-m-10
    {
        width: 83.3333333333%;
    }
    
    .col-m-11 
    {
        width: 91.6666666667%;
    }
      
    .col-m-12 
    {
        width: 100%;
    }
}

// Colonne tablet
//--------------------------------------------------------------------///
@include eq-tablet
{
    .col-t-1
    {
        width: 8.3333333333%;
    }
    
    .col-t-2
    {
        width: 16.6666666667%;
    }
    
    .col-t-3
    {
        width: 25%;
    }
    
    .col-t-4
    {
        width: 33.3333333333%;
    }
    
    .col-t-5
    {
        width: 41.6666666667%;
    }
    
    .col-t-6
    {
        width: 50%;
    }
    
    .col-t-7
    {
        width: 58.3333333333%;
    }
    
    .col-t-8
    {
        width: 66.6666666667%;
    }
    
    .col-t-9
    {
        width: 75%;
    }
    
    .col-t-10
    {
        width: 83.3333333333%;
    }
    
    .col-t-11 
    {
        width: 91.6666666667%;
    }
      
    .col-t-12 
    {
        width: 100%;
    }
}


// Colonne desktop
//--------------------------------------------------------------------///
@include at-desktop
{
    .col-d-1
    {
        width: 8.3333333333%;
    }
    
    .col-d-2
    {
        width: 16.6666666667%;
    }
    
    .col-d-3
    {
        width: 25%;
    }
    
    .col-d-4
    {
        width: 33.3333333333%;
    }
    
    .col-d-5
    {
        width: 41.6666666667%;
    }
    
    .col-d-6
    {
        width: 50%;
    }
    
    .col-d-7
    {
        width: 58.3333333333%;
    }
    
    .col-d-8
    {
        width: 66.6666666667%;
    }
    
    .col-d-9
    {
        width: 75%;
    }
    
    .col-d-10
    {
        width: 83.3333333333%;
    }
    
    .col-d-11 
    {
        width: 91.6666666667%;
    }
      
    .col-d-12 
    {
        width: 100%;
    }
}
