//##########################################//
//                                          //
//          STILE COMPONENTI FORM           //
//                                          //
//##########################################//

form
{
    position: relative;
    margin: 0px;

    > *
    {
        opacity: 1;
        transition: opacity $timeFast $easeCubicInOut;
    }

    &::after
    {
        content: '';
        @include center();
        @include size(80px);
        background-image: url('../../img/share/loader.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        opacity: 0;
        visibility: hidden;
        -webkit-animation: infiniteRotation 1s linear infinite;
        -moz-animation:    infiniteRotation 1s linear infinite;
        -o-animation:      infiniteRotation 1s linear infinite;
        animation:         infiniteRotation 1s linear infinite;
        transition: visibility 0s linear $timeFast,
                    opacity $timeFast $easeCubicInOut;
    }

    &.loading
    {
        > *
        {
            pointer-events: none;
            opacity: 0.2;
        }
        
        &::after
        {
            opacity: 1;
            visibility: visible;
            transition: visibility 0s linear,
                    opacity $timeFast $easeCubicInOut;
        }
    }
}

.form-group-title
{
    span
    {
        display: block;
    }

    &__title
    {
        color: $primary;
    }

    &__subtitle
    {
        margin-top: 10px;
        text-transform: uppercase;
        font-weight: $font-weight-bold;
        color: $secondary;
    }
}

.form-group
{
    
    &:not(.checkbox-wrap):not(.radios-wrap)
    {
        position: relative;
        padding-top: 27px;

        > label
        {
            position: absolute;
            top: 0px;
            left: 0px;
            margin-bottom: 9px;
        }
    }

    > label
    {
        cursor: pointer;
        display: inline-block;
        font-size: em(15px);
        line-height: em(18px, 15px);
        font-weight: $font-weight-bold;

        a
        {
            color: $accent;
            text-decoration: none;

            &:hover
            {
                text-decoration: underline;
            }
        }
    }

    select
    {
        @extend %select_form;
    }

    input[type=text],
    input[type=email],
    input[type=number]
    {
        @extend %input_form;
    }

    textarea
    {
        @extend %textarea_form;
    }

    &.checkbox-wrap
    {
        @include flexbox();
        @include flex-wrap(wrap);

        input
        {
            margin-right: 10px;
        }

        label
        {
            width: calc(100% - 30px);
            font-weight: $font-weight-semibold;
        }
    }

    input[type=checkbox]
    {
        @extend %input_checkbox_form;
    }

    &.radios-wrap
    {
        .radios-wrap__column
        {
            @include flexbox();
            @include flex-wrap(wrap);

            input
            {
                margin-right: 10px;
            }

            label
            {
                width: calc(100% - 30px);
            }
        }

        @include pre-desktop
        {
            .radios-wrap__column
            {
                &:not(:first-child)
                {
                    margin-top: 15px;
                }
            }
        }

        @include at-desktop
        {
            @include flexbox();
            @include flex-wrap(wrap);

            .radios-wrap__column
            {
                width: calc(50% - 10px);

                &:not(:first-child)
                {
                    margin-left: 20px;
                }
            }
        }
    }

    input[type=radio]
    {
        @extend %input_radio_form;
    }

    .slider
    {
        height: 20px;
        max-width: calc(100% - 2px);
        margin: 0px auto;

        &__track
        {
            top: 8px;
            height: 5px;

            &-0,
            &-2
            {
                background: $gray_bright;
            }

            &-1
            {
                background: $secondary_accent_h;
            }
        }

        &__thumb
        {
            cursor: pointer;
            top: 0px;
            @include size(20px);
            border-radius: 20px;
            outline: none;

            .circle
            {
                @include center();
                @include size(6px);
                background-color: $white;
                border-radius: 3px;
                z-index: 2;
            }

            .drop
            {
                @include centerY();
                @include size(20px);
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                z-index: 1;
            }
            
            &-0
            {
                background-color: $secondary;

                .drop
                {
                    left: 45%;
                    background-image: url('../../img/share/slider-curve-sx.svg');
                }
            }
            
            &-1
            {
                background-color: $accent;

                .drop
                {
                    right: 45%;
                    background-image: url('../../img/share/slider-curve-dx.svg');
                }
            }
        }

        &__wrap-label
        {
            margin-top: 5px;
        }

        &__label
        {
            font-size: em(13px);
            line-height: em(20px, 13px);
            font-weight: $font-weight-semibold;
        }

        &.disabled
        {
            opacity: 0.5;
        }
    }

    &.multi-select
    {
        .im-accordion
        {
            position: relative;

            &__toggle
            {
                @extend %select_form;
            }

            &__content
            {
                position: absolute;
                top: calc(100% + 10px);
                left: 0px;
                right: 0px;
                width: 100%;
                z-index: 100;

                border-radius: 5px;
                background-color: $white;
                box-shadow: 0px 10px 40px rgba(0, 104, 150, 0.2);

                [data-wrapper-height]
                {
                    padding: 5px;
                }

                ul
                {
                    li
                    {
                        &:not(:first-child)
                        {
                            margin-top: 3px;
                        }

                        input
                        {
                            display: none;

                            &:checked ~ label
                            {
                                background-repeat: no-repeat;
                                background-size: 17px;
                                background-position: calc(100% - 13px) center;
                                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%2300A0DF' fill-rule='evenodd' d='M20.707 6.293a1 1 0 0 1 0 1.414l-10 10a1 1 0 0 1-1.414 0l-5-5a1 1 0 1 1 1.414-1.414L10 15.586l9.293-9.293a1 1 0 0 1 1.414 0Z' clip-rule='evenodd'/%3E%3C/svg%3E");
                                background-color: $secondary_light;
                            }
                        }

                        label
                        {
                            cursor: pointer;
                            display: block;
                            padding: 12px 36px 12px 15px;
                            font-size: em(12px);
                            line-height: em(16px, 12px);
                            font-weight: $font-weight-bold;
                            background-color: $white;
                            border-radius: 5px;
                            transition: background-color $timeFast $easeCubicInOut;

                            &:hover
                            {
                                background-color: $secondary_light;
                            }
                        }
                    }
                }
            }
        }

        &.disabled
        {
            .im-accordion
            {
                &__toggle
                {
                    pointer-events: none;
                    color: $gray_light !important;
                    -webkit-box-shadow: inset 0px 0px 0px 2px transparent !important;
                    -moz-box-shadow: inset 0px 0px 0px 2px transparent !important;
                    box-shadow: inset 0px 0px 0px 2px transparent !important;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%23E3E3E3' d='m15.724 11.033-3.693 3.693a.934.934 0 0 1-1.322 0l-3.693-3.693c-.59-.59-.172-1.597.66-1.597h7.387c.833 0 1.25 1.008.661 1.597Z'/%3E%3C/svg%3E") !important;
                }
            }

            > label
            {
                pointer-events: none;
                color: $gray_light !important;
            }
        }
    }

    &.error
    {
        &:not(.checkbox-wrap)
        {
            > label
            {
                color: $red !important;
            }
        }

        input[type=text],
        input[type=email],
        input[type=number],
        textarea
        {
            color: $red !important;
            -webkit-box-shadow: inset 0px 0px 0px 2px $red !important;
            -moz-box-shadow: inset 0px 0px 0px 2px $red !important;
            box-shadow: inset 0px 0px 0px 2px $red !important;

            &::placeholder { 
                color: $red !important;
                opacity: 1;
            }
              
            &:-ms-input-placeholder { 
                color: $red !important;
            }
            
            &::-ms-input-placeholder {
                color: $red !important;
            }
        }

        select
        {
            color: $red !important;
            -webkit-box-shadow: inset 0px 0px 0px 2px $red !important;
            -moz-box-shadow: inset 0px 0px 0px 2px $red !important;
            box-shadow: inset 0px 0px 0px 2px $red !important;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%23E20B0B' d='m15.724 11.033-3.693 3.693a.934.934 0 0 1-1.322 0l-3.693-3.693c-.59-.59-.172-1.597.66-1.597h7.387c.833 0 1.25 1.008.661 1.597Z'/%3E%3C/svg%3E") !important;
        }

        .error-message
        {
            display: block;
            margin-top: 2px;
            font-size: em(13px);
            line-height: em(20px, 13px);
            font-weight: $font-weight-bold;
            color: $red;
        }
    }
}

.required-info
{
    display: block;
    width: 100%;
    font-size: em(15px);
    line-height: em(25px, 22px);
    font-weight: $font-weight-bold;
    color: $secondary;
}

.privacy-disclaimer
{
    &__intro
    {
        display: block;
        width: 100%;
        color: $gray_dark;
        font-weight: $font-weight-medium;
    }

    &__question
    {
        &-label
        {
            display: block;
            color: $gray_dark;
            font-weight: $font-weight-medium;
        }
    }

    @include eq-mobile
    {
        &__intro
        {
            margin-bottom: 25px;
        }

        &__question
        {
            margin-top: 25px;
        }
    }

    @include at-tablet
    {
        &__intro
        {
            margin-bottom: 50px;
        }

        &__question
        {
            margin-top: 50px;
        }
    }

    @include pre-desktop
    {
        &__question
        {
            .radios-wrap
            {
                margin-top: 20px;
            }
        }
    }

    @include at-desktop
    {
        &__question
        {
            @include flexbox();
            @include flex-wrap(wrap);
            @include justify-content(space-between);

            &-label
            {
                width: calc(100% - 380px);
            }

            .radios-wrap
            {
                width: 350px !important;
            }
        }
    }
}