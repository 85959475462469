//##########################################//
//                                          //
//      MARGINI                             //
//                                          //
//##########################################//


// Margin small
//--------------------------------------------------------------------///
.m-small
{
    margin: $margin-small;
}

.m-t-small
{
    margin-top: $margin-small;
}

.m-b-small
{
    margin-bottom: $margin-small;
}

.m-l-small
{
    margin-left: $margin-small;
}

.m-r-small
{
    margin-left: $margin-small;
}

// Margin medium
//--------------------------------------------------------------------///
.m-medium
{
    margin: $margin-medium;
}

.m-t-medium
{
    margin-top: $margin-medium;
}

.m-b-medium
{
    margin-bottom: $margin-medium;
}

.m-l-medium
{
    margin-left: $margin-medium;
}

.m-r-medium
{
    margin-left: $margin-medium;
}

// Margin large
//--------------------------------------------------------------------///
.m-large
{
    margin: $margin-large;
}

.m-t-large
{
    margin-top: $margin-large;
}

.m-b-large
{
    margin-bottom: $margin-large;
}

.m-l-large
{
    margin-left: $margin-large;
}

.m-r-large
{
    margin-left: $margin-large;
}