//##########################################//
//                                          //
//        STILE GENERALE DEL SITO           //
//                                          //
//##########################################//



// Set Typography
//--------------------------------------------------------------------//
h1,
h2,
h3,
h4,
h5,
h6
{
    margin: 0px;
    font-weight: normal;
}

.h1
{
    @extend %h1;

    @include eq-mobile
    {
        font-size: em(35px);
        line-height: em(41px, 35px);
    }
    @include at-tablet
    {
        font-size: em(80px);
        line-height: em(94px, 80px);
    }
}

.h2
{
    @extend %h2;

    @include eq-mobile
    {
        font-size: em(30px);
        line-height: em(38px, 30px);
    }
    @include at-tablet
    {
        font-size: em(65px);
        line-height: em(75px, 65px);
    }
}

.h3
{
    @extend %h3;

    @include eq-mobile
    {
        font-size: em(25px);
        line-height: em(35px, 25px);
    }
    @include at-tablet
    {
        font-size: em(55px);
        line-height: em(65px, 55px);
    }
}

.h4
{
    @extend %h4;

    @include eq-mobile
    {
        font-size: em(22px);
        line-height: em(30px, 22px);
    }
    @include at-tablet
    {
        font-size: em(40px);
        line-height: em(48px, 40px);
    }
}

.h5
{
    @extend %h5;

    @include eq-mobile
    {
        font-size: em(18px);
        line-height: em(21px, 18px);
    }
    @include at-tablet
    {
        font-size: em(30px);
        line-height: em(35px, 30px);
    }
}

.h6
{
    @extend %h6;

    @include eq-mobile
    {
        font-size: em(16px);
        line-height: em(19px, 16px);
    }
    @include at-tablet
    {
        font-size: em(25px);
        line-height: em(30px, 25px);
    }
}

p,
.p
{
    @extend %p;
    
    @include eq-mobile
    {
        font-size: em(15px);
        line-height: em(18px, 15px);
    }
    @include at-tablet
    {
        font-size: em(18px);
        line-height: em(30px, 18px);
    }
}

.p-big
{
    @extend %p;

    @include eq-mobile
    {
        font-size: em(15px);
        line-height: em(18px, 15px);
    }
    @include at-tablet
    {
        font-size: em(18px);
        line-height: em(30px, 18px);
    }
}

.p-small
{
    @extend %p;


    @include eq-mobile
    {
        font-size: em(14px);
        line-height: em(16px, 14px);
    }
    @include at-tablet
    {
        font-size: em(15px);
        line-height: em(18px, 15px);
    }
}

.label
{
    @extend %p;
    font-size: em(14px);
    line-height: em(18px, 15px);
    text-transform: uppercase;
    font-weight: $font-weight-bold; 
}

.data-bold
{
    @extend %data_bold;
}

.quote
{
    @extend %p;
    font-size: em(26px);
    line-height: em(32px, 26px);
    font-style: italic;
}

a
{
    @extend %text_link;
}

ul
{
    list-style: none;
    padding: 0px;
    margin: 0px;
    font-size: em(16px);
}


strong, 
b
{
    font-weight: $font-weight-bold;
}

i, em 
{
    font-style: italic;
}

.text-content 
{
    position: relative;
    width: 100%;
    color: $black;

    > *:not(:first-child):not(:last-child)
    {
        margin: 30px 0px;
    }
    
    > *:first-child
    {
        margin-bottom: 30px;
    }
    
    > *:last-child
    {
        margin-top: 30px;
    }

    > *:only-child
    {
        margin: 0px;
    }
  
    ul 
    {
        @extend %p;
        list-style-type: disc;
        padding: 0 0 10px 20px;

        li 
        {
            padding: 0 0 10px 0;
        }
    }

    ol 
    {
        @extend %p;
        list-style-type: decimal;
        padding: 0 0 10px 20px;
    
        li 
        {
            padding: 0 0 10px 0;
    
        }
    }
  
    video 
    {
        margin: 0;   
        @include size(100%, auto);
    }

    iframe 
    {
        margin: 0;   
        @include size(100%, auto);
    }
  
    img 
    {
        margin: 0 0 20px 0;
        @include size(auto);
        max-width: 100%;
    }
}
  

.img-content
{
    img
    {
        display: block;
        max-width: 100%;
    }
}

.button-primary
{
    @extend %primary_button;
    color: $white !important;
    background-color: $accent;

    &.icon
    {
        @include flexbox();
        @include flex-wrap(wrap);
        @include align-items(center);
        width: fit-content;
    }

    span
    {
        & ~ svg
        {
            margin-left: 10px;
        }
    }

    svg
    {
        display: inline-block;
        @include size(20px);
        fill: $white;

        & ~ span
        {
            margin-left: 10px;
        }
    }

    &:disabled,
    &.disabled
    {
        cursor: default;
        pointer-events: none;
        color: $gray_light !important;
        background-color: $gray_bright !important;

        svg
        {
            fill: $gray_light !important;
        }
    }

    @include at-tablet
    {
        &:hover
        {
            background-color: $accent_medium !important;
        }
        
        &:active
        {
            background-color: $accent_dark !important;
        }
    }
}

.button-secondary
{
    @extend %primary_button;
    color: $accent !important;
    background-color: transparent;
    @include box-shadow(0px, 0px, 0px, 1px, $accent, true);

    &.icon
    {
        @include flexbox();
        @include flex-wrap(wrap);
        @include align-items(center);
        width: fit-content;
    }

    span
    {
        & ~ svg
        {
            margin-left: 10px;
        }
    }

    svg
    {
        display: inline-block;
        @include size(20px);
        fill: $accent;

        & ~ span
        {
            margin-left: 10px;
        }
    }

    &:disabled,
    &.disabled
    {
        cursor: default;
        pointer-events: none;
        color: $gray_light !important;
        background-color: transparent !important;
        @include box-shadow(0px, 0px, 0px, 1px, $gray_light, true);

        svg
        {
            fill: $gray_light !important;
        }
    }

    @include at-tablet
    {
        &:hover
        {
            color: $white !important;
            background-color: $accent_medium !important;
            @include box-shadow(0px, 0px, 0px, 1px, $accent_medium, true);

            svg
            {
                fill: $white;
            }
        }
        
        &:active
        {
            color: $white !important;
            background-color: $accent_dark !important;
            @include box-shadow(0px, 0px, 0px, 1px, $accent_dark, true);

            svg
            {
                fill: $white;
            }
        }
    }
}

.button-text
{
    @extend %text_button;
    color: $accent !important;

    span,
    svg
    {
        display: inline-block;
        vertical-align: middle;
    }

    svg
    {
        @include size(18px);
        fill: $accent !important;
        transition: fill $timeFast $easeQuadInOut;
    }

    @include at-tablet
    {
        &:hover
        {
            color: $accent_medium !important;

            svg
            {
                fill: $accent_medium !important;
            }
        }
        
        &:active
        {
            color: $accent_dark !important;

            svg
            {
                fill: $accent_dark !important;
            }
        }
    }
}

.tag-btn
{
    cursor: pointer;
    @include flexbox();
    @include flex-wrap(wrap);
    @include justify-content(center);
    @include align-items(center);
    width: fit-content;
    padding: 5px 10px;
    font-size: em(12px);
    line-height: em(18px);
    font-weight: $font-weight-bold;
    text-decoration: none;
    border-radius: 5px;
    transition: color $timeFast $easeQuadInOut,
                background-color $timeFast $easeQuadInOut;

    svg
    {
        display: inline-block;
        @include size(16px);
        transition: fill $timeFast $easeQuadInOut;
    }

    &.left
    {
        svg
        {
            margin-right: 5px;
        }
    }
    
    &.right
    {
        svg
        {
            margin-left: 5px;
        }
    }

    &.primary
    {
        color: $primary;
        background-color: $gray_bright;

        svg
        {
            fill: $primary;
        }
    }

    &.accent
    {
        color: $white;
        background-color: $accent;

        svg
        {
            fill: $white;
        }
    }

    &.secondary
    {
        color: $white;
        background-color: $secondary;

        svg
        {
            fill: $white;
        }
    }

    @include at-tablet
    {
        &.primary:hover
        {
            color: $secondary;
            background-color: $gray_bright;

            svg
            {
                fill: $secondary;
            }
        }

        &.accent:hover
        {
            background-color: $accent_medium;
        }

        &.secondary:hover
        {
            background-color: $secondary_medium;
        }
    }
}

.d-none
{
    display: none !important;
}

//--------------------------------------------------------------------//




