//##########################################//
//                                          //
//            RESET HTML DEL SITO           //
//                                          //
//##########################################//

*
{
    box-sizing: border-box;

    &:before,
    &:after
    {
        box-sizing: border-box;
    }
}

html
{
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

html,
body
{
    width: 100%;
    padding: 0;
    margin: 0;
}

body
{
    display: block;
    position: relative;
    font-family: $font-family-primary;
    font-weight: $font-weight-regular;
    color: $primary;
    background-color: #fff;
    overflow-x: hidden;

    // Fix Raleway numbers
    -webkit-font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
    -moz-font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
    font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
}

#main-content
{
    background-color: $white;
}

picture, 
img
{
    display: block;
}