
                  @import "~scss/helpers/mixins";
                  @import "~scss/helpers/resolutions";
                  @import "~scss/common/variables";
                  @import "~scss/common/animations";
                  @import "~scss/common/colors";
                  @import "~scss/common/typography";
                
// COMPONENTE Coming Soon
//--------------------------------------------------------------------//

.coming-soon {
  
  &__nation{
    & path{
      transition: opacity 0.2s ease-in-out;
      fill: $secondary;
    }

    &:hover path{
      opacity: 0.8;
    }

    &--active path{
      fill: $accent;
    }
  }

  &__background{
    background: linear-gradient(360deg, #005187 -97.5%, #060721 98.67%);
    padding: 100px 130px;

    @include eq-tablet{
      padding: 0 60px;
    }

    @include eq-mobile{
      padding: 0 40px;
    }

    h2{
      margin: 0;
      color: $white;
    }
  }

  &__wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px auto;
    max-width: 100%;
    flex-wrap: wrap-reverse;
  

  }

  &__subtitle{
    font-weight: $font-weight-bold;
    font-size: 27px;
    line-height: 35px;
    color: white;
    text-transform: uppercase;
    margin: 0;
    padding-top: 30px;
  }

  &__title{
    padding-top: 30px;
    font-weight: $font-weight-bold;
    font-size: 30px;
    line-height: 40px;
    position: absolute;
    width: 40%;
    color: $white;
    margin-top: 20px;

    strong {
      color: $secondary;
    }

    @include at-tablet{
      width: 52%;
    }


    @include eq-mobile{
      font-size: 22px;
      line-height: 24px;
      width: 100%;
      position: initial;

    }

  }

  &__map{
    margin: 0 0 0 auto;

    @include eq-desktop{
      width: 50%;
      svg{
          width: 100%;
      }
    }

      @include eq-mobile{
        svg{
          width: 100%;
          height: auto;
          margin-top: 40px;
        }
    }

    @include eq-tablet{
      margin-top: 280px;
    }
  
  }

  .legenda_maps {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 50px 0;

      @include eq-mobile{
        padding: 30px 0 0;
        justify-content: flex-end;
      }

      div {
          position: relative;
          padding: 0 25px;
          font-size: 16px;
          line-height: 30px;
          color: #fff;
          &:after {
              content: '';
              position: absolute;
              left: 0;
              width: 20px;
              height: 20px;
              border-radius: 50%;
              overflow: hidden;
              top: 5px;

          }
          &.disponibile {

              &:after {
                  background: $secondary;
              }
          }
          &.in_arrivo {

              &:after {
                  background: $accent;
              }
          }
      }
  }


  &__words{
    opacity: 0;
    pointer-events: none;
    z-index: 0;
    transition: opacity 0.2s linear;
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: absolute;

    &--container{
      position: relative;
      width: 30%;
      height: 500px;
      margin-top: 300px;

      @include eq-desktop{
          width: 50%;
      }

      @include  eq-tablet{
        width: 100%;
        margin-top: 20px;
        height: 400px;
      }

      @include  eq-mobile{
        width: 100%;
        margin-top: 40px;
        height: 300px;
      }

  
    }

    
    &__title,
    &__title:visited{
      text-transform: uppercase;
      color: $white;
      font-weight: $font-weight-bold;
      font-size: 50px;
      line-height: 60px;
      opacity: 0.2;
      transition: opacity 0.5s linear;
      cursor: default;
      pointer-events: none;
      text-decoration: none;

      @include  eq-mobile{
        font-size: 40px;
        line-height: 47px;
      }

      &.active,
      &:visited.active{
        pointer-events: all;
        cursor: pointer;
        opacity: 1;
        
      }

    }



    &--active{
      opacity: 1;
      pointer-events: all;
      z-index: 1;
    }
  }
  
}


//--------------------------------------------------------------------//