//##########################################//
//                                          //
//      STILE PERSONALIZZATO SWIPER         //
//                                          //
//##########################################//

.swiper-button-next,
.swiper-button-prev
{
    &::after
    {
        display: none;
    }

    svg
    {
        @include size(24px);
    }
}

.swiper-container-horizontal
{
    > .swiper-pagination-progressbar
    {
        top: auto !important;
        bottom: 0px;
    }
}