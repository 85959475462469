//##########################################//
//                                          //
//      OFFSET GRIGLIA                     //
//                                          //
//##########################################//

// offset generici, per tutti i breakpoints
//--------------------------------------------------------------------///
.offset-1
{
    margin-right: 8.3333333333%;
}

.offset-2
{
    margin-right: 16.6666666667%;
}

.offset-3
{
    margin-right: 25%;
}

.offset-4
{
    margin-right: 33.3333333333%;
}

.offset-5
{
    margin-right: 41.6666666667%;
}

.offset-6
{
    margin-right: 50%;
}

.offset-7
{
    margin-right: 58.3333333333%;
}

.offset-8
{
    margin-right: 66.6666666667%;
}

.offset-9
{
    margin-right: 75%;
}

.offset-10
{
    margin-right: 83.3333333333%;
}

.offset-11 
{
    margin-right: 91.6666666667%;
}
  
.offset-12 
{
    margin-right: 100%;
}

// offset mobile
//--------------------------------------------------------------------///
@include eq-mobile
{
    .offset-m-1
    {
        margin-right: 8.3333333333%;
    }
    
    .offset-m-2
    {
        margin-right: 16.6666666667%;
    }
    
    .offset-m-3
    {
        margin-right: 25%;
    }
    
    .offset-m-4
    {
        margin-right: 33.3333333333%;
    }
    
    .offset-m-5
    {
        margin-right: 41.6666666667%;
    }
    
    .offset-m-6
    {
        margin-right: 50%;
    }
    
    .offset-m-7
    {
        margin-right: 58.3333333333%;
    }
    
    .offset-m-8
    {
        margin-right: 66.6666666667%;
    }
    
    .offset-m-9
    {
        margin-right: 75%;
    }
    
    .offset-m-10
    {
        margin-right: 83.3333333333%;
    }
    
    .offset-m-11 
    {
        margin-right: 91.6666666667%;
    }
      
    .offset-m-12 
    {
        margin-right: 100%;
    }
}

// offset tablet
//--------------------------------------------------------------------///
@include eq-tablet
{
    .offset-t-1
    {
        margin-right: 8.3333333333%;
    }
    
    .offset-t-2
    {
        margin-right: 16.6666666667%;
    }
    
    .offset-t-3
    {
        margin-right: 25%;
    }
    
    .offset-t-4
    {
        margin-right: 33.3333333333%;
    }
    
    .offset-t-5
    {
        margin-right: 41.6666666667%;
    }
    
    .offset-t-6
    {
        margin-right: 50%;
    }
    
    .offset-t-7
    {
        margin-right: 58.3333333333%;
    }
    
    .offset-t-8
    {
        margin-right: 66.6666666667%;
    }
    
    .offset-t-9
    {
        margin-right: 75%;
    }
    
    .offset-t-10
    {
        margin-right: 83.3333333333%;
    }
    
    .offset-t-11 
    {
        margin-right: 91.6666666667%;
    }
      
    .offset-t-12 
    {
        margin-right: 100%;
    }
}


// offset desktop
//--------------------------------------------------------------------///
@include at-desktop
{
    .offset-d-1
    {
        margin-right: 8.3333333333%;
    }
    
    .offset-d-2
    {
        margin-right: 16.6666666667%;
    }
    
    .offset-d-3
    {
        margin-right: 25%;
    }
    
    .offset-d-4
    {
        margin-right: 33.3333333333%;
    }
    
    .offset-d-5
    {
        margin-right: 41.6666666667%;
    }
    
    .offset-d-6
    {
        margin-right: 50%;
    }
    
    .offset-d-7
    {
        margin-right: 58.3333333333%;
    }
    
    .offset-d-8
    {
        margin-right: 66.6666666667%;
    }
    
    .offset-d-9
    {
        margin-right: 75%;
    }
    
    .offset-d-10
    {
        margin-right: 83.3333333333%;
    }
    
    .offset-d-11 
    {
        margin-right: 91.6666666667%;
    }
      
    .offset-d-12 
    {
        margin-right: 100%;
    }
}
