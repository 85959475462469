
                  @import "~scss/helpers/mixins";
                  @import "~scss/helpers/resolutions";
                  @import "~scss/common/variables";
                  @import "~scss/common/animations";
                  @import "~scss/common/colors";
                  @import "~scss/common/typography";
                
// COMPONENTE Footer
//--------------------------------------------------------------------//
.footer__component {
    width: 100%;
    .footer__wrapper {
        position: relative;
        width: 100%;
        max-width: 1440px;
        margin: 0 auto;
        padding: 0 30px;
        padding: 90px 0px;
        text-align: center;
        color: $primary;

        @include eq-mobile {
            padding: 90px 20px;
            font-size: em(14px);
            line-height: em(24px);
        }
        @include at-tablet {
            font-size: em(14px);
            line-height: em(24px);
        }
        > img {
          display: inline-block;
        }
        .legal-info {
            margin-top: 30px;
            p {
                margin: 0;
            }
        }
        .service-menu-wrap {
            @include flexbox();
            @include justify-content(center);
            margin-top: 30px;
            text-align: center;
            a {
                padding: 0 20px;
                text-decoration: none;
                color: $primary;
                @include eq-mobile {
                    padding: 0 10px;
                }
                &:first-child {
                    padding-left: 0;
                }
                &:last-child {
                    padding-right: 0;
                }
            }
        }
        .copyright {
            margin-top: 10px;
        }
    }
}